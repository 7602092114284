import type { FC } from 'react';
import { TemplatePage } from '@genially/public-web-components';
import WithLayout from '../WithLayout';
import parseBreadcrumbs from 'core/infrastructure/parseBreadcrumbs';
import { translate } from 'core/infrastructure/translator';

interface DataProps {
  content: any;
}

const Template: FC<DataProps> = ({ content }) => {
  const translateFromContent = (key: string) => translate(content.langcode.value, key);
  const subcat = content.related
    ? content.related.length > 0
      ? content.related[1]
        ? content.related[1]
        : content.related[0]
      : content.related[0]
    : null;

  const categoriesRelated = content.related
    ? subcat
      ? subcat.category.mainRelatedContents
        ? subcat.category.mainRelatedContents.entities
        : null
      : null
    : null;
  const related = categoriesRelated
    ? categoriesRelated.filter(
        (template: any) =>
          template.bundle === 'template' &&
          template.uuid !== content.uuid &&
          template.excluded !== true
      )
    : null;

  const ctaLang = content.langcode.value === 'en' ? '/' : '/' + content.langcode.value + '/';

  const customBanner = {
    entity: {
      animation: 'none',
      behaviorSettings: {
        rowStyle: {
          background: {
            backgroundType: 'solid',
            baseColor: {
              color: 'rAkihabara',
            },
          },
        },
      },
      buttonStyle: {
        size: 'big',
        variant: 'tertiary',
      },
      button: {
        title: translateFromContent('templates.templatebanner3'),
        url: {
          path: 'https://auth.genially.com' + ctaLang + 'signup',
        },
      },
      langcode: {
        value: content.langcode.value,
      },
      subtitle: translateFromContent('templates.templatebanner2'),
      subtitleType: 'paragraphL',
      subtitleColor: 'rWhite',
      title: translateFromContent('templates.templatebanner1'),
      titleColor: 'rWhite',
      titleType: 'title2XXL',
      type: 'ParagraphBanner',
    },
  };

  const breadcrumbs = parseBreadcrumbs(content, 'template');

  const info = [
    { text: translateFromContent('templates.detailinfo1') },
    { text: translateFromContent('templates.detailinfo2') },
    { text: translateFromContent('templates.detailinfo3') },
    { text: translateFromContent('templates.detailinfo4') },
    { text: translateFromContent('templates.detailinfo5') },
  ];

  return (
    <TemplatePage
      title={content.displayTitle}
      banner={customBanner}
      description={content.description ? content.description.value : undefined}
      breadcrumbItems={breadcrumbs}
      teaser={content.teaser}
      idView={content.IdView}
      categories={content.categories ? parseCategories(content.categories, true) : null}
      categoriesTitle={translateFromContent('templates.categories')}
      useTemplate={translateFromContent('templates.usetemplate')}
      relatedTemplates={related}
      relatedTemplatesTitle={translateFromContent('templates.related')}
      relatedTemplatesCTA={translateFromContent('templates.relatedcta')}
      idGenially={content.IdGenially}
      lang={ctaLang}
      info={info}
      changed={content.changed}
      price={content.price}
    />
  );
};

export default WithLayout(Template);

export const parseCategories = (categories?: any, notBreadcrumb?: boolean): any => {
  if (!categories) return;
  const landings: any = [];
  categories.forEach((category: any) => {
    const nodes = category.category.mainRelatedContents
      ? category.category.mainRelatedContents.entities
      : null;
    const landing = nodes
      ? nodes.filter((template: any) => template.bundle === 'template_category')
      : null;
    if (!notBreadcrumb) {
      if (!category.category.parent && !landings[0]) {
        landings[0] = landing;
      }
      if (category.category.parent && category.category.parent.length > 0 && !landings[1]) {
        landings[1] = landing;
      }
    } else {
      landings.push(landing);
    }
  });

  return landings;
};
